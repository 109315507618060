import { Link } from "gatsby"
import React, { useState } from "react"
import YAMLData from "../../books/books.yml"

const bookLinks = YAMLData
  .filter(element => !!element.name)
  .map(element => ({
    name: `/${element.name.toUpperCase()}/`,
    link: `/books/${element.slug}/`
  }));
const menuItems = [
  { name: "/HOME/", link: "/" },
  { name: "/ABOUT/", link: "/about/" },
  {
    name: "/ARTICLES/",
    link: "/articles/",
    children: [
      { name: "/..", link: "/" },
      { name: "/2021/", link: "/2021/" },
    ],
  },
  {
    name: "/BOOKS/",
    link: "/books/",
    children: [
      { name: "/..", link: "/" },
      ...bookLinks
      // { name: "/ACCELERATE/", link: "/books/accelerate/" },
      // { name: "/ARTIFICIAL INTELLIGENCE/", link: "/books/artificial-intelligence/" },
      // { name: "/CLEAN ARCHITECTURE/", link: "/books/clean-architecture/" },
      // { name: "/CLEAN CODE/", link: "/books/clean-code/" },
      // { name: "/COMPUTER ORGANIZATION AND DESIGN/", link: "/books/computer-organization-and-design/" },
      // { name: "/CONTINUOUS DELIVERY/", link: "/books/continuous-delivery/" },
      // { name: "/DATABASE INTERNALS/", link: "/books/database-internals/" },
      // { name: "/DEAR FOUNDER/", link: "/books/dear-founder/" },
      // { name: "/DESIGN PATTERNS/", link: "/books/design-patterns/" },
      // { name: "/DESIGNING DATA-INTENSIVE APPLICATIONS/", link: "/books/designing-data-intensive-applications/" },
      // { name: "/DESIGNING DISTRIBUTED SYSTEMS/", link: "/books/designing-distributed-systems/" },
      // { name: "/DISTRIBUTED SYSTEMS/", link: "/books/distributed-systems/" },
      // { name: "/EFFECTIVE JAVA/", link: "/books/effective-java/" },
      // { name: "/FLOW ARCHITECTURES/", link: "/books/flow-architectures/" },
      // { name: "/HACKER'S DELIGHT/", link: "/books/hackers-delight/" },
      // { name: "/HOW LINUX WORKS/", link: "/books/how-linux-works/" },
      // { name: "/THE LINUX COMMAND LINE/", link: "/books/the-linux-command-line/" },
      // { name: "/THE LINUX PROGRAMMING INTERFACE/", link: "/books/the-linux-programming-interface/" },
      // { name: "/THE MANAGER'S PATH/", link: "/books/the-managers-path/" },
      // { name: "/MODERN JAVA/", link: "/books/modern-java/" },
      // { name: "/REFACTORING/", link: "/books/refactoring/" },
      // { name: "/RELEASE IT!/", link: "/books/release-it/" },
      // { name: "/WORKING EFFECTIVELY WITH LEGACY CODE/", link: "/books/working-effectively-with-legacy-code/" },
      // { name: "/ZERO TRUST NETWORKS/", link: "/books/zero-trust-networks/" },
    ],
  },
  {
    name: "/PROJECTS/",
    link: "/projects/",
    children: [
      { name: "/..", link: "/" },
      // { name: "/WEBSITE CHAT/", link: "/website-chat/" },
      // { name: "/HOUSE APP/", link: "/house-app/" },
      { name: "/MMORPG/", link: "/projects/mmorpg/" },
    ]
  },
  { name: "/GLOSSARY/", link: "/glossary/" },
]

const SideBar = () => {
  const selected = typeof window !== 'undefined' ? window.location.pathname : "";
  const selectedItem = menuItems.find(item => {
    if (item.link === selected) return true;
    const foundItem = (item.children || []).find(el => el.link === selected)
    return !!foundItem;
  });
  const items = (selectedItem && selectedItem.hasOwnProperty("children") && !!selectedItem.children.length) ? selectedItem.children : menuItems;
  return (
    <nav>
      <ul style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        {items.map(link => (
          <li
            key={link.name}
            style={{
              listStyleType: `none`,
              margin: 0,
              width: "15rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Link
              to={link.link}
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bold",
                fontSize: "0.7rem",
              }}
            >
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default SideBar