import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = () => (
  <footer style={{ fontSize: "0.7rem", textAlign: "center" }}>
    © {new Date().getFullYear()} db
  </footer>
)

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
