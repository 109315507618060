import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header style={{ marginBottom: `1.45rem` }}>
    <div style={{ margin: `0 auto`, padding: `1.5rem 1rem`, display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Link to="/" style={{ textDecoration: "none", fontSize: "0px", lineHeight: "0px", position: "absolute", left: "2rem", top: "0.75rem" }}>
        <svg width="3rem" height="3rem">
          <rect width="3rem" height="3rem" style={{ fill: `rgb(0,0,0)`, strokeWidth: 3, stroke:`rgb(0,0,0)` }} />
        </svg>
      </Link>
      <span style={{ textAlign: "center", fontWeight: "bold", height: "1.5rem" }}>
        {typeof window !== 'undefined' ? (window.location.pathname === "/" ? "/home/" : window.location.pathname) : ''}
      </span>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
